import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { apiAddVersion, apiGetProfiles } from '../../../APIService';
import { KRMListBox } from "../../../components/KRMListBox/KRMListBox";

import '../AddApplication.css'

const AddAVersion = (props) => {
  const [apkName, setApkName] = useState(props.selectedVersion == null ? '' : props.selectedVersion.name)
  const [apk, setApk] = useState(null)
  const [apkError, setApkError] = useState('')
  const [loading, setLoading] = useState(false)
  const [profiles, setProfiles] = useState([])
  const [selProfile, setSelProfile] = useState(props.selectedVersion?.profile?.map(item => {
                                                return item?.id}))

  const apkInputRef = useRef(null);
  const toast = useRef(props.refToast)

  const getProfiles = () => {
    apiGetProfiles().then(response => {
      const items = response.map((item) => {
        return {name: item.serial, code: item.profile_id}
      })
      items.push({name: 'Общий профиль', code: null}) 
      setProfiles(items)
    })
  }

  useEffect(() => {
    getProfiles()
  }, []);


  async function onAdd() {
    if (!validate()) {
      return;
    }

    setLoading(true)
    let isAdd = (props.selectedVersion == null)

    const formData = new FormData();
   
    if (isAdd) {
      formData.append('app_id', props.selectedApp.app_id);
    }

    formData.append('apk_name', apkName);
      
    if (!isAdd) {
      formData.append('id', props.selectedVersion.id);
    }

    formData.append("apk", apk);
    formData.append("profiles", selProfile?.map(item => item === null ? "null" : item).join(","));
    
    const result = await apiAddVersion(formData)
    if (result.result) {
      toast.current.current.show({ severity: 'success', summary: 'Успешно', detail: `${isAdd ? 'Версия добавлена' : 'Изменения сохранены'}`, life: 3000 })
      props.onRefresh()
    }
    else {
      const errorText = result?.errorText ?? 'Изменения не сохранены' 
      toast.current.current.show({ severity: 'error', summary: 'Ошибка', detail: errorText, life: 3000 })
    }

    setLoading(false)
  }

  const onProfileChange = (val) => {
    setSelProfile(val);
  }

  const apkBtnClick = () => {
    apkInputRef.current.click();
  }

  const onApkChange = (e) => {
    const selectedFile = e.target.files[0];
      if (selectedFile !== undefined) {
        setApk(selectedFile)
        setApkName(selectedFile.name)
        setApkError('')
      }
  }

  const validate = () => {

    if (apkName === '') {
      setApkError('Добавьте apk-файл')
      return false
    }

    return true;
  }


  const footer = () => {
    return (
      <div>
        <Button label="Отмена" icon="pi pi-times" onClick={props.onHide} className="p-button-text" />
        <Button disabled={loading} label='Сохранить' icon="pi pi-check" onClick={onAdd} autoFocus />
      </div>
    );
  }

  return (
    <div>
      <Dialog visible={props.visible} onHide={props.onHide} 
        className='add-version-dialog' headerClassName='add-dialog-header' footer={footer} footerClassName='add-dialog-footer'
        header={props.selectedVersion == null ? 'Добавить версию' : 'Редактировать версию'}> 
        <div className="add-container krm-add-version">
          {loading && 
            <div className="add-container-loading">
              <ProgressSpinner strokeWidth="3" style={{ width: '50px', height: '50px' }}/>
            </div>
          }
          <span className="field-add">
            <span className='add-apk'>
              <Button disabled={loading} onClick={apkBtnClick} icon = {`pi pi-fw ${apkName === '' ? 'pi-plus' : 'pi-pencil'}`} label={apkName === '' ? 'Добавить apk-файл' : `Заменить ${apkName}`}></Button>
              <input type="file" ref={apkInputRef} accept=".apk" style={{ display: 'none' }} onChange={onApkChange}/>
            </span>              
            <small id="apk-name-error" className="p-error">{apkError}</small>
          </span>
          <KRMListBox options={profiles} value={selProfile} showAllLabel={false} label='Профили' onChange={onProfileChange} />
        </div>
      
      </Dialog>
    </div>      
  )
}

export default AddAVersion