import { getToken } from './utils/utils';

let API_V0_URL = null;


const loadConfig = async () => {
    if (API_V0_URL === null || API_V0_URL === undefined) {
    const response = await fetch('/config.json');
    const config = await response.json();

    API_V0_URL = config.REACT_APP_API_V0_URL;
  }
};

export async function apiGetApplications() {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/apps`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
    else {
      console.log(`Error in apiGetApplications, ${response.status}`)
    }
  } catch (error) {
    console.log(`Exception in apiGetApplications, ${error}`)
  }
  return []
}

export async function apiAddApp(data) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/app/add`, {
      method: 'POST',  
      body: data,
      headers: {
        'Authorization': `Bearer ${getToken()}`
      },
    });

    return response.ok
  }
  catch (error) {
    console.log(`Exception in apiAddApp, ${error}`)
  }

  return false;
}

export async function apiRemoveApp(appId) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/app/${appId}`, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${getToken()}`
      },
    });

    return response.ok
  } catch (error) {
    console.log(`Exception in apiRemoveApp, ${error}`)
  }

  return false
}

export async function apiAddVersion(data) {
  await loadConfig();

  return apiPOSTExchange('/app/version/add', data)
}

export async function apiPOSTExchange(url, data) {
  await loadConfig();

  let result = false;
  let errorText = '';
  try {
    const response = await fetch(`${API_V0_URL}${url}`, {
      method: "POST",
      body: data,
      headers: {
        'Authorization': `Bearer ${getToken()}`
      },
    });

    result = response.ok;
    if (!result) {
      errorText = await response.text();
    }
  }
  catch (error) {
    console.log(`Exception in apiPOSTExchange, ${error}`)
  }

  return {result, errorText};
}

export async function apiRemoveVersion(versionId) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/app/version/${versionId}`, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${getToken()}`
      },
    });

    return response.ok
  } catch (error) {
    console.log(`Exception in apiRemoveVersion, ${error}`)
  }

  return false
}

export async function apiLogin(data) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/admin/auth/`, {
      mode: 'cors',
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${data}`
        },
        
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  }
  catch (error) {
    console.log(`Exception apiLogin ${error}`)
  }
  throw new Error('Error login')
}

export async function apiGetCategories() {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/categories`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
    else {
      console.log(`Error in apiGetCategories, ${response.status}`)
    }
  } catch (error) {
    console.log(`Exception in apiGetCategories, ${error}`)
  }
  return []
}

export async function apiAddCategory(data) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/category/add`, {
      method: 'POST',  
      body: data,
      headers: {
        'Authorization': `Bearer ${getToken()}`
      },
    });

    return response.ok
  }
  catch (error) {
    console.log(`Exception apiAddCategory, ${error}`)
  }

  return false;
}

export async function apiRemoveCategory(categoryId) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/category/${categoryId}`, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${getToken()}`
      },
    });
    if (response.ok) { 
      return 0 
    }
    else if (response.status === 424) {
      return response.status
    }
  } catch (error) {
    console.log(`Error in apiRemoveCategory, ${error}`)
  }

  return 1
}

export async function apiGetProfiles() {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/profiles`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
    else {
      console.log(`Error in apiGetProfiles, ${response.status}`)
    }
  } catch (error) {
    console.log(`Exception apiGetProfiles, ${error}`)
  }
  return []
}

export async function apiAddProfile(data) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/profiles/add`, {
      method: 'POST',  
      body: data,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
    });

    return response.ok
  }
  catch (error) {
    console.log(`Exception in apiAddProfile, ${error}`)
  }

  return false;
}

export async function apiRemoveProfile(profileId) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/profiles/${profileId}`, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${getToken()}`
      },
    });
    if (response.ok) { 
      return 0 
    }
    else if (response.status === 424) {
      return response.status
    }
  } catch (error) {
    console.log(`Exception in apiRemoveProfile, ${error}`)
  }

  return 1
}

export async function apiGetAppProfiles(profileId) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/settings/app_profile/${profileId}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
    else {
      console.log(`Error inapiGetAppProfiles, ${response.status}`)
    }
  } catch (error) {
    console.log(`Exception in apiGetAppProfiles, ${error}`)
  }
  return []
}

export async function apiGetAppAvailableProfiles(profileId) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/settings/app_profile/available/${profileId}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
    else {
      console.log(`Error in apiGetAppAvailableProfiles, ${response.status}`)
    }
  } catch (error) {
    console.log(`Exception in apiGetAppAvailableProfiles, ${error}`)
  }
  return []
}

export async function apiAddAppProfiles(data) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/settings/app_profile/add`, {
      method: 'POST',  
      body: data,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
    });

    return response.ok
  }
  catch (error) {
    console.log(`Exception in apiAddProfile, ${error}`)
  }

  return false;
}

export async function apiRemoveAppProfiles(id) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/settings/app_profile/${id}`, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${getToken()}`
      },
    });

    return response.ok
  } catch (error) {
    console.log(`Exception in apiRemoveAppProfiles, ${error}`)
  }

  return false
}

export async function apiGetCategoryProfiles(profileId) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/settings/category_profile/${profileId}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
    else {
      console.log(`Error in apiGetCategoryProfiles, ${response.status}`)
    }
  } catch (error) {
    console.log(`Exception in apiGetCategoryProfiles, ${error}`)
  }
  return []
}

export async function apiGetCategoryAvailableProfiles(profileId) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/settings/category_profile/available/${profileId}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
    else {
      console.log(`Error in apiGetCategoryAvailableProfiles, ${response.status}`)
    }
  } catch (error) {
    console.log(`Exception in apiGetCategoryAvailableProfiles, ${error}`)
  }
  return []
}

export async function apiAddCategoryProfiles(data) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/settings/category_profile/add`, {
      method: 'POST',  
      body: data,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
    });

    return response.ok
  }
  catch (error) {
    console.log(`Exception in apiAddCategoryProfiles, ${error}`)
  }

  return false;
}

export async function apiRemoveCategoryProfiles(id) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/settings/category_profile/${id}`, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${getToken()}`
      },
    });

    return response.ok
  } catch (error) {
    console.log(`Exception in apiRemoveCategoryProfiles, ${error}`)
  }

  return false
}

export async function apiGetStatistics(data) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/statistics`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
    else {
      console.log(`Error in apiGetStatistics, ${response.status}`)
    }
  } catch (error) {
    console.log(`Exception in apiGetStatistics, ${error}`)
  }
  return []
}


export async function apiGetUsers() {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/admins`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
    else {
      console.log(`Error in apiGetUsers, ${response.status}`)
    }
  } catch (error) {
    console.log(`Exception inapiGetUsers, ${error}`)
  }
  return []
}

export async function apiIsUserExists(data) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/admin/exists?userName=${data}`, {
      method: 'GET',  
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
    });

    return response.status === 200
  }
  catch (error) {
    console.log(`Exception in apiIsUserExists, ${error}`)
  }

  return false;
}

export async function apiAddUser(data) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/admin/add`, {
      method: 'POST',  
      body: data,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
    });

    return response.ok
  }
  catch (error) {
    console.log(`Exception in apiAddUser, ${error}`)
  }

  return false;
}

export async function apiRemoveUser(id) {
  await loadConfig();

  try {
    const response = await fetch(`${API_V0_URL}/admin/${id}`, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${getToken()}`
      },
    });
    
    return response.ok 
  } catch (error) {
    console.log(`Exception in apiRemoveUser, ${error}`)
  }

  return false
}